import React, { useEffect, useState } from "react";
import Tit from "@/components/tit";
import echarts from "echarts/lib/echarts";
import moment from "moment"; // 引入柱状图
import "echarts/lib/chart/bar"; // 引入提示框和标题组件
import "echarts/lib/component/markPoint";
import "echarts/lib/component/markLine";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import { keepTwoDecimalFull } from "@/utils/common";
import "./index.less";
const _ = require('lodash');
function PowerAmount(props) {
    const [data, setData] = useState();
    const setChartFun = (data) => {
        try {
            const myfChart = echarts.init(document.getElementById("PowerAmount-bd-chart"));
            const tms2 = [], minMaxRain = [];
            data.map((item, index) => {
                tms2.push(moment(item[`month`]).format('MM月'))
                minMaxRain.push(Number(item[`flow`]))
            });
            const maxLabeY = _.max(minMaxRain)
            minMaxRain.map((item, index) => {
                // 标记柱形最大值
                minMaxRain[index] = {
                    value: Number(item) ? keepTwoDecimalFull(Number(item), 2) : 0,
                };
                minMaxRain[index][`label`] = {
                    show: true, //开启显示
                    position: "top", //在上方显示
                    textStyle: {
                        //数值样式
                        color: '#fff332',
                        fontSize: 10,
                        fontWeight: 700,
                    },
                };
            });
            myfChart.setOption({
                title: { text: "" },
                grid: {
                    left: "10%",
                    top: 30,
                    bottom: "17%",
                    right: "5%",
                },
                // tooltip: {
                //     trigger: "axis",
                //     formatter: function (params) {
                //         return (
                //             "时间：" +
                //             moment(params[0].name).format("MM月DD日") +
                //             "<br>降雨：" +
                //             (isNaN(params[0].value)
                //                 ? "-"
                //                 : parseFloat(params[0].value).toFixed(1) + "mm")
                //         );
                //     },
                // },

                xAxis: {
                    nameTextStyle: {
                        color: "#6FD7E8",
                        fontSize: 12,
                        align: "middle",
                        verticalAlign: "bottom",
                        padding: [0, 0, 10, 0],
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#404f60",
                        },
                    },
                    data: tms2,
                    position: "bottom",
                    boundaryGap: ["20%", "20%"], //设置为true代表离零刻度间隔一段距离
                    axisLabel: {
                        textStyle: {
                            color: "#68A8CC",
                            fontSize: 12,
                            margin: 10,
                        },

                        formatter: (val) => {
                            return val;
                        },
                        alignWithLabel: true,
                    },
                    splitLine: {
                        show: false,
                    },
                },
                yAxis: {
                    name: "",
                    nameLocation: "end",
                    nameTextStyle: {
                        color: "#68A8CC",
                        fontSize: 12,
                        align: "middle",
                        verticalAlign: "bottom",
                        padding: [0, 30, 0, 0],
                    },
                    axisTick: {
                        show: false,
                    },
                    type: "value",
                    inverse: false,
                    min: 0,
                    max: maxLabeY,
                    axisLabel: {
                        rotate: 0,
                        formatter: function (value, index) {
                            return value.toFixed(1);
                        },
                        textStyle: {
                            color: "#6FD7E8", //更改坐标轴文字颜色
                            fontSize: 10, //更改坐标轴文字大小
                        },
                    },
                    splitLine: { show: false },
                    axisLine: {
                        lineStyle: {
                            color: "#404f60",
                        },
                    },
                },
                series: [
                    {
                        barWidth: 20, //柱图宽度
                        barGap: 30, //柱图间距
                        name: "销量",
                        type: "bar",
                        data: minMaxRain,
                        color: "#6AA9FC",
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1, //渐变色在下面修改，这里是透明度
                                    [
                                        {
                                            offset: 0,
                                            color: "#67C7E8",
                                        },
                                        {
                                            offset: 1,
                                            color: "#051934",
                                        },
                                    ]
                                ),
                            },
                        },
                    },
                ],
            });
            window.addEventListener("resize", () => {
                myfChart.resize();
            });
        } catch (err) {
            console.log(err)
        }

    }
    useEffect(() => {
        const dataCopy = {}
        const powerGenerationSum = props.powerGenerationSum
        if (!_.isEmpty(powerGenerationSum)) {
            for (let i in powerGenerationSum) {
                if (i == "powerGenerationSumAll" || i == "powerGenerationThisYear") {
                    if (powerGenerationSum[i] < 0) {
                        powerGenerationSum[i] = 0
                    }
                    dataCopy[i] = String(keepTwoDecimalFull(powerGenerationSum[i] / 10000, 2)).split(".");
                }
            }
            powerGenerationSum.EcologicalFlowList.map((item => {
                item[`flow`] = keepTwoDecimalFull((item[`generatorFlow`]) / 10000, 2)
            }))
            setChartFun(powerGenerationSum.EcologicalFlowList)
            setData(dataCopy)
        }

    }, [props])
    return !props.loading && <div className="PowerAmount-wrap wrap">
        <Tit tit={<div>发电量汇总<span className="m-l-10 fz-14 c68">(万KW·h)</span></div>}></Tit>
        <div className="PowerAmount-hd">
            <div className="PowerAmount-hd_right PowerAmount-hd_item flex flex-a-c">
                <div className="PowerAmount-hd_item-logo right"></div>
                <div className="flex-1 m-l-15 flex flex-j-b flex-a-c">
                    <div className="item-desc fz-24">
                        今年发电量合计
                    </div>
                    <div className="fz-16">
                        {data && data[`powerGenerationThisYear`] ? (
                            <span className="item-num">
                                <i className="fz-48">{data[`powerGenerationThisYear`][0]}</i>.{data[`powerGenerationThisYear`][1]}
                            </span>
                        ) : (
                            <span>
                                <i className="fz-48">0</i>
                            </span>
                        )}
                        <span className="fz-16 m-l-10">万kW·h</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="PowerAmount-bd m-t-20">
            <div className="PowerAmount-bd-chart" id="PowerAmount-bd-chart"></div>
        </div>
    </div >
}
export default PowerAmount